<template>
  <div :id="id" class="modal fade" ref="generalModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog" :class="modalSize">
      <div class="modal-content" :class="modalContentClass">
        <div class="modal-header border-bottom-0" v-if="!hideHeader">
          <h5 class="modal-title text-primary-app">{{ title }}</h5>
          <button
            v-if="!hideHeaderClose"
            type="button"
            class="btn-close"
            @click="hide()"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <slot name="bodyContent">...</slot>
        </div>
        <div v-if="!hideFooter" class="modal-footer border-top-0">
          <slot name="footerContent">
            <button type="button" class="btn btn-secondary" @click="hide()">Close</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Modal } from 'bootstrap'
import { computed, onMounted, toRefs, ref } from 'vue'

const props = defineProps({
  id: { type: String, required: false, default: 'generalModal' },
  title: { type: String, required: false, default: 'Modal title' },
  size: { type: String, required: false, default: 'sm' },
  modalContentClass: { type: String, required: false, default: '' },
  hideHeaderClose: { type: Boolean, required: false, default: false },
  noCloseOnBackdrop: { type: Boolean, required: false, default: false },
  noCloseOnEsc: { type: Boolean, required: false, default: false },
  hideFooter: { type: Boolean, required: false, default: false },
  hideHeader: { type: Boolean, required: false, default: false },
  backdropOpacity: { type: Number, required: false, default: 0.5 }, // Nueva propiedad
  noModalBackdrop: { type: Boolean, required: false, default: false }
})

const {
  title,
  hideHeaderClose,
  noCloseOnBackdrop,
  noCloseOnEsc,
  modalContentClass,
  hideFooter,
  id,
  backdropOpacity
} = toRefs(props)
const generalModal = ref(null)
let modalInstance = null

const modalSize = computed(() => {
  if (props.size === 'fullscreen') return 'modal-fullscreen'
  return `modal-${props.size || 'sm'}`
})

const toggle = () => {
  modalInstance?.toggle()
}

const show = () => {
  modalInstance?.show()
}

const hide = () => {
  modalInstance?.hide()
}

const emit = defineEmits(['shown', 'hidden'])

const handleModalShown = () => {
  const backdropElement = document.querySelector('.modal-backdrop')
  if (backdropElement) {
    if (props.noModalBackdrop) {
      backdropElement.style.display = 'none'
    } else {
      backdropElement.style.opacity = backdropOpacity.value
    }
  }
  emit('shown')
}
const handleModalHidden = () => emit('hidden')

onMounted(() => {
  modalInstance = new Modal(generalModal.value, {
    backdrop: noCloseOnBackdrop.value ? 'static' : true,
    keyboard: !noCloseOnEsc.value
  })
  generalModal.value.addEventListener('shown.bs.modal', handleModalShown)
  generalModal.value.addEventListener('hidden.bs.modal', handleModalHidden)
})

defineExpose({
  toggle,
  show,
  hide
})
</script>
