<template>
  <GeneralModal
    ref="modalRefPromotionalFirstBuy"
    @shown="onShown"
    title="Promoción de bienvenida"
    :hideHeader="true"
    size="lg"
    modalContentClass="p-5 confirmationModal"
    hideHeaderClose
    hideFooter
    :noModalBackdrop="noModalBackdrop"
  >
    <template #bodyContent>
      <div class="position-relative">
        <!-- Animaciones de confeti -->
        <div
          class="position-absolute top-0 start-50 translate-middle-x"
          v-if="isShown && !noConfetti"
        >
          <Vue3Lottie
            ref="vue3LottieRef"
            :loop="1"
            @onComplete="resetLottie(vue3LottieRef)"
            :animationData="Confetti"
          />
        </div>
        <div class="position-absolute top-0 start-0" v-if="isShown && !noConfetti">
          <Vue3Lottie
            ref="vue3LottieRef2"
            :loop="1"
            @onComplete="resetLottie(vue3LottieRef2)"
            :animationData="Confetti"
          />
        </div>
        <div v-if="promoimage" class="text-center">
          <img :src="promoimage" alt="imagen de promoción" class="img-fluid w-70" />
        </div>

        <!-- Mensaje principal con ícono de regalo 🎁 -->
        <div class="d-flex align-items-center justify-content-center text-center position-relative">
          <div>
            <font-awesome-icon :icon="['fas', 'gift']" class="text-warning me-2 fa-lg" />
            <span class="fs-2 fw-semibold text-dark">{{ promoConfig?.message }}</span>
          </div>
        </div>

        <hr />

        <!-- Términos y condiciones con ícono de documento 📜 -->
        <div class="text-center">
          <p class="fs-5 text-muted fw-light">
            <font-awesome-icon :icon="['fas', 'file-contract']" class="text-secondary me-2" />
            Términos y condiciones
          </p>
          <small class="text-muted d-block px-2" v-html="promoConfig?.terms"></small>
        </div>
      </div>
    </template>
  </GeneralModal>
</template>

<script setup>
import GeneralModal from '@/components/shared/GeneralModal.vue'
import Confetti from '@/assets/lotties/Confetti.json'
import { ref, computed, toRefs } from 'vue'
import { useConfigStore } from '@/stores/config'
import promoimage from '@/assets/images/shared/solo-10.jpg'

const props = defineProps({
  noModalBackdrop: { type: Boolean, required: false, default: false },
  noConfetti: { type: Boolean, required: false, default: false }
})

const { noModalBackdrop, noConfetti } = toRefs(props)

const modalRefPromotionalFirstBuy = ref(null)
const vue3LottieRef = ref(null)
const vue3LottieRef2 = ref(null)
const isShown = ref(false)

const configStore = useConfigStore()
const promoConfig = computed(() => ({
  message:
    configStore.getPromotionFirstBuyConfig?.message ||
    '¡Bienvenido a Dinamica! Obtén un 10% de descuento en tu primera compra',
  terms:
    configStore.getPromotionFirstBuyConfig?.terms ||
    '<p class="m-0">Obtén un 10% de descuento <strong>sobre lo facturado</strong> en tu primera compra en línea. </p><p>Si el total de tu pedido está entre $6,000 y hasta $20,000</p><p>Si tu compra facturada supera los $20,000 la NC será máximo de $2,000</p><p>Se descuenta al momento de tu pago.</p><p>Esta promoción aplica a clientes que tienen esa línea de crédito disponible o bien pagan de contado.</p><p>Promoción válida del 14 al 28 de febrero 2025</p>'
}))

const showModal = () => {
  modalRefPromotionalFirstBuy.value.show()
}

const hideModal = () => {
  isShown.value = false
  modalRefPromotionalFirstBuy.value.hide()
}

const onShown = () => {
  isShown.value = true
}

// Función para reiniciar el confeti si es necesario
const resetLottie = (lottieRef) => {
  if (lottieRef.value) {
    lottieRef.value.destroy()
  }
}

defineExpose({
  showModal,
  hideModal
})
</script>
