<template>
  <div :class="dynamicBaseClass">
    <!-- Mensaje de progreso para alcanzar el mínimo -->
    <slot name="messageLogicNotAcomplished">
      <div
        v-if="!logicStateAcomplished && !minmaxLogic"
        class="d-flex justify-content-between align-items-center"
        v-html="dynamicMessageLogic"
      ></div>
      <div
        v-if="!logicStateAcomplished && minmaxLogic"
        class="d-flex justify-content-between align-items-center"
      >
        <span>
          Agrega
          <strong>{{ $formatcurrency(logicDataMin - total) }}</strong> para obtener el 10% de
          descuento para tu primera compra.
          <a class="text-muted cursor-pointer" @click="openTerms">Términos y condiciones</a>.
        </span>
      </div>
    </slot>

    <!-- Mensaje de progreso cuando se ha alcanzado o superado el mínimo -->
    <slot name="messageLogicAcomplished">
      <div
        v-if="logicStateAcomplished && !minmaxLogic"
        class="d-flex justify-content-between align-items-center"
      >
        <div v-html="dynamicMessageLogicAcomplished" v-if="total > 0"></div>
      </div>
      <div
        v-if="logicStateAcomplished && minmaxLogic"
        class="d-flex justify-content-between align-items-center"
      >
        <span>
          ¡Felicidades! Tu carrito
          <span>{{ total === logicDataMin ? 'iguala' : 'supera' }}</span> los
          <strong>{{ $formatcurrency(logicDataMin) }}</strong
          >. Obtendrás un <strong>10%</strong> de descuento en tu primera compra. Este descuento
          aplica con un monto máximo en nota de crédito de <strong>$2,000</strong> por cliente,
          <strong>sobre lo facturado</strong>, sigue comprando para completar tu pedido.
          <a class="text-muted cursor-pointer" @click="openTerms"> Términos y condiciones </a>.
        </span>
      </div>
    </slot>

    <!-- Barra de progreso -->
    <div :class="progressClass" v-if="!logicStateAcomplished && !minmaxLogic">
      <div
        class="progress-bar"
        :style="{ width: progressPercentage + '%' }"
        role="progressbar"
        :aria-valuenow="progressPercentage"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <!-- Barra de progreso -->
    <div :class="progressClass" v-if="minmaxLogic && total < logicDataMin">
      <div
        class="progress-bar"
        :style="{ width: progressPercentageMinMaxLogic + '%' }"
        role="progressbar"
        :aria-valuenow="progressPercentageMinMaxLogic"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <FirstBuyPromo
      ref="firstBuyPromoRef"
      v-if="isActiveFirstBuyPromo && !hasWebPurchases"
      :noModalBackdrop="noModalBackdropFirstBuy"
      noConfetti
    />
  </div>
</template>

<script setup>
import FirstBuyPromo from '@/components/account/FirstBuyPromo.vue'
import { toRefs, computed, ref } from 'vue'
import { useUserStore } from '@/stores/user'
import { useConfigStore } from '@/stores/config'

const userStore = useUserStore()
const configStore = useConfigStore()
const isActiveFirstBuyPromo = computed(() => configStore.getStatePromotionFirstBuy)
const hasWebPurchases = computed(() => userStore.hasWebPurchases)
const firstBuyPromoRef = ref()

const openTerms = () => {
  firstBuyPromoRef.value.showModal()
}

// Define props
const props = defineProps({
  noModalBackdropFirstBuy: { type: Boolean, required: false, default: false },
  minmaxLogic: { type: Boolean, required: false, default: false },
  minimunLogic: { type: Boolean, required: false, default: false },
  remainLogic: { type: Boolean, required: false, default: false },
  containerBaseClasses: { type: String, required: false, default: 'alert' },
  progressClass: { type: String, required: false, default: 'progress' },
  userBalance: { type: Number, required: false, default: 0 },
  logicClass: { type: String, required: false, default: 'alert-warning' },
  logicAcomplishedClass: { type: String, required: false, default: 'alert-success' },
  logicAcomplishedClassMin: { type: String, required: false, default: 'alert-primary' },
  logicAcomplishedClassMax: { type: String, required: false, default: 'alert-success' },
  logicData: { type: Number, required: false, default: 4000 },
  logicDataMin: { type: Number, required: false, default: 0 },
  logicDataMax: { type: Number, required: false, default: 0 },
  total: { type: Number, required: false, default: 0 },
  messageLogicNotAcomplished: { type: String, required: false, default: '' },
  messageLogicAcomplished: { type: String, required: false, default: '' },
  formatCurrency: { type: Function, required: false, default: (value) => value.toFixed(2) } // Prop opcional para formateo de moneda
})

// Referencias
const {
  noModalBackdropFirstBuy,
  minmaxLogic,
  minimunLogic,
  remainLogic,
  containerBaseClasses,
  progressClass,
  userBalance,
  logicClass,
  logicAcomplishedClassMax,
  logicAcomplishedClassMin,
  logicDataMin,
  logicDataMax,
  logicAcomplishedClass,
  logicData,
  total,
  messageLogicNotAcomplished,
  messageLogicAcomplished,
  formatCurrency
} = toRefs(props)

// Clase dinámica del contenedor basado en el progreso
const dynamicBaseClass = computed(() => {
  if (minmaxLogic.value) {
    if (total.value >= logicDataMin.value && total.value < logicDataMax.value) {
      return `${containerBaseClasses.value} ${logicAcomplishedClassMin.value}`
    }
    if (total.value >= logicDataMax.value) {
      return `${containerBaseClasses.value} ${logicAcomplishedClassMax.value}`
    }
    return `${containerBaseClasses.value} ${logicClass.value}`
  }
  return !logicStateAcomplished.value
    ? `${containerBaseClasses.value} ${logicClass.value}`
    : `${containerBaseClasses.value} ${logicAcomplishedClass.value}`
})

const logicStateAcomplished = computed(() => {
  if (remainLogic.value) {
    if (total.value > 0) return true
    if (total.value <= 0) return false
  }
  if (minimunLogic.value) {
    if (total.value >= logicData.value) return true
    if (total.value < logicData.value) return false
  }
  if (minmaxLogic.value) {
    if (total.value >= logicDataMin.value) return true
    if (total.value < logicDataMin.value) return false
  }
  return total.value >= logicData.value
})

// const getDiscountTotal = computed(() => {
//   if (total.value < logicDataMax.value) {
//     const calculateDiscount = (total.value * 10) / 100
//     return calculateDiscount
//   }
//   if (total.value >= logicDataMax.value) {
//     const calculateDiscount = (logicDataMax.value * 10) / 100
//     return calculateDiscount
//   }
//   return ''
// })

// Cálculo del progreso basado en la prop `logicData`
const progressPercentage = computed(() => {
  const logicMinimun = Math.min((total.value / logicData.value) * 100, 100)
  const logicRemain = Math.min((logicData.value / userBalance.value) * 100, 100)
  return remainLogic.value ? logicRemain : logicMinimun
})

const progressPercentageMinMaxLogic = computed(() => {
  const isTotalLessThanMin = total.value < logicDataMin.value
  const isTotalMoreThanMax = total.value > logicDataMax.value
  const logicMin = Math.min((total.value / logicDataMin.value) * 100, 100)
  const logicMax = Math.min(
    (isTotalMoreThanMax ? logicDataMax.value : total.value / logicDataMax.value) * 100,
    100
  )
  return isTotalLessThanMin ? logicMin : logicMax
})

// Mensaje dinámico cuando el total es menor al mínimo
const dynamicMessageLogic = computed(() => {
  return messageLogicNotAcomplished.value
    ? messageLogicNotAcomplished.value
    : `<span>Agrega <strong>${formatCurrency.value(logicData.value - total.value)}</strong> para poder realizar tu pedido.</span>`
})

// Mensaje dinámico cuando se ha alcanzado el mínimo
const dynamicMessageLogicAcomplished = computed(() => {
  return messageLogicAcomplished.value
    ? messageLogicAcomplished.value
    : `<span>¡Felicidades! Tu carrito supera los <strong>${formatCurrency.value(logicData.value)}</strong>, sigue comprando para completar tu pedido.</span>`
})
</script>
