import * as Sentry from '@sentry/vue'

export function initSentry(app, router) {
  const dsn = import.meta.env.VITE_SENTRY_DSN
  const tracePropagationTargets = import.meta.env.VITE_TRACE_PROPAGATION_TARGETS.split(',')
  const environment = import.meta.env.VITE_ENVIRONMENT

  Sentry.init({
    app,
    dsn,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    tracePropagationTargets,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment,
    beforeSend(event) {
      if (!event.exception || !event.exception.values || event.exception.values.length === 0) {
        return event
      }

      const errorMessage = event?.exception?.values[0]?.value || ''
      const errorType = event?.exception?.values[0]?.type || ''
      const requestUrl = event?.request?.url || ''

      // Lista de errores HTTP a ignorar
      const ignoredHttpErrors = ['401', '403', '404', '500']
      const isHttpError = ignoredHttpErrors.some((code) =>
        errorMessage.includes(`status code ${code}`)
      )

      // Ignorar errores de Axios y errores de red
      if (
        errorType === 'AxiosError' ||
        isHttpError ||
        errorMessage.includes('Network Error') ||
        requestUrl.includes('/api/') // Opcional: Ignorar cualquier error de API
      ) {
        return null
      }

      return event
    }
  })
}
